.List {
  padding: 10px 0px;
}

.List-crossed {
  text-decoration: line-through;
  color: #808B96 !important;
}

.List-dropdown > .menu {
  max-height: 14em !important;
}

.List-checkbox {
  width: 100%;
  font-size: 16px !important;
}

.List-item {
  padding: 8px 10px;
  margin: 5px 5px 5px 10px;
  background: #eee;
  border-radius: 5px;
}

.green {
  border-left: 5px solid #32CD32;
  border-right: 5px solid #32CD32;
}

.green-select {
  color: #32CD32;
  background: #32CD32;
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.blue {
  border-left: 5px solid #0E6EB8;
  border-right: 5px solid #0E6EB8;
}

.blue-select {
  color: #0E6EB8;
  background: #0E6EB8;
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.grey {
  border-left: 5px solid #A0A0A0;
  border-right: 5px solid #A0A0A0;
}

.grey-select {
  color: #A0A0A0;
  background: #A0A0A0;
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.purple {
  border-left: 5px solid #B413EC;
  border-right: 5px solid #B413EC;
}

.purple-select {
  color: #B413EC;
  background: #B413EC;
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.red {
  border-left: 5px solid #B03060;
  border-right: 5px solid #B03060;
}

.red-select {
  color: #B03060;
  background: #B03060;
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.List-input {
  font-size: 16px !important;
  width: calc(100% - 20px);
  margin: auto 10px 5px 10px;
}

.List-button {
  font-size: 16px !important;
  width: 100%;
  padding: 13.5px 14px !important;
}

.List-header-container {
  position: absolute;
  width: 100%;
}

.List-footer-container {
  position: absolute;
  width: calc(100% - 20px);
  bottom: 0;
  margin: 10px;
  text-align: right;
}

.List-header {
  display: flex;
  width: calc(100% - 20px);
  justify-content: space-between;
  margin: auto 10px 10px 10px;
}

.List-header-input-left {
  flex: 100;
  width: 100%;
}

.List-header-input-right {
  flex: 1;
  width: 100%;
}

.List-header-input-menu {
  width: calc(100% - 10px);
}

.List-header-input-menu > .basic {
  width: 100%;
  min-width: 0px !important;
}

.List-header-button {
  margin: 0 -2px 0 0 !important;
}

.List-header-button > .button {
  padding: 15px 18px !important;
}

.ui.compact.menu {
  box-shadow: none !important;
  border: none;
}

.List-items {
  margin-top: 105px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 155px);
}

.List-items::-webkit-scrollbar {
  width: 5px !important;
}

.List-items::-webkit-scrollbar-track-piece {
  background-color: white;
}

.ui {
  font-size: 16px !important;
}

.List-modal-input {
  width: 100%;
  margin: auto 0px;
}

.List-Modal-error {
  margin-top: 10px;
  color: #ff695e;
}

.ui.toggle.checkbox input:focus:checked~label,
.ui.toggle.checkbox input:checked~label{
  color: white !important;
}

.ui.toggle.checkbox > label:before {
  background: rgba(0,0,0,.75) !important;
}

.List-settings-label {
  margin: 5px auto;
}

.List-settings-label > label {
  color: white !important;
}
